<!--  -->
<template>
  <div class="box">
    <div @click="getSmsCode()">1</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1, //1=代发货,2=自提发货
      mobile: "",
      disabled: false, // 禁用按钮
      list: [], //代发货
      page: 0, //页数
    };
  },
  mounted() {
    this.getCardList(); //登录
    // this.getSmsCode();//发送验证码
  },
  methods: {
    getMobile() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (userInfo == false) {
        this.$toast("缓存读取失败");
        return false;
      }
      this.mobile = userInfo.mobile;
    },
    //下滑分页
    slideDownward() {
      this.getCardList();
    },

    //获取卡片列表
    getCardList() {
      let _this = this;
      _this.getMobile();
      let data = {
        mobile: _this.mobile,
        type: _this.type,
        page: ++_this.page,
      };
      this.cardList({ data }).then((res) => {
        let ret = res.data;
        if (ret.data.data.data.length <= 0) {
          this.$toast("没有更多了");
          return false;
        }
        _this.list = [...this.list, ...ret.data.data.data];
        _this.page = ret.data.data.current_page;
      });
    },
  },
};
</script>